<template>
  <div class="row">
    <Card class="col-8">
      <template #header>
        <div
          class="d-flex align-items-center justify-content-between"
          style="width: 100%"
        >
          <div>
            <h4>Firma Kartı</h4>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-4">
          <b-form-group class="add-style">
            <label for="vergiNo">Vergi Kimlik No </label>
            <input
              class="form-control"
              v-model="tax_number"
              type="text"
              id="vergiNo"
              required
            />
          </b-form-group>
        </div>
        <div class="col-8">
          <b-form-group class="add-style">
            <label for="unvan">Ünvan </label>
            <input
              class="form-control"
              v-model="title"
              type="text"
              id="unvan"
              required
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <div class="form-group">
            <label for="yetkiBelgeNo">Sınıfı </label>
            <select
              v-model="class_company"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option value="1">Sahibinden</option>
              <option value="2">Emlak Ofisi</option>
              <option value="3">Banka</option>
              <option value="4">İnşaat Firması</option>
              <option selected value="5">Diğer</option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="form-group">
            <label for="yetkiBelgeNo"
              >Taşınmaz Ticareti Yetki Belgesi
              <span style="color: #7a7a7a; font-size: 17px">⋇</span>
            </label>
            <select
              v-model="real_estate_trade"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option value="" selected>Seçiniz</option>
              <option value="1">Var</option>
              <option value="2">Yok</option>
            </select>
          </div>
        </div>
        <div class="col-6 mt-4">
          <b-form-group class="add-style">
            <label for="yetkiBelgeNo">Yetki Belge Numarası </label>
            <input
              v-model="document_number"
              class="form-control"
              type="text"
              id="yetkiBelgeNo"
              required
            />
          </b-form-group>
        </div>
        <div class="col-6 mt-4">
          <b-form-group class="add-style">
            <label for="yetkiBelgeNo">Düzenleme Tarihi </label>
            <input
              class="form-control"
              type="date"
              id="duzenlemeTarih"
              required
              v-model="issue_date"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label for="yetkiBelgeNo">Site Üyelik Tarihi </label>
            <input
              class="form-control"
              type="date"
              id="duzenlemeTarih"
              required
              v-model="site_membership"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label for="ısTelefonu">İş Telefonu </label>
            <masked-input
                    class="form-control form-control-lg"
                    v-model="office_phone"
                    mask="(111) 111-11-11"
                    placeholder="+90 (5__) ___-____"
                  />
            
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label for="cepTelefonu">Cep Telefonu </label>
            <masked-input
                    class="form-control form-control-lg"
                    v-model="phone"
                    mask="(111) 111-11-11"
                    id="cepTelefonu"

                    placeholder="+90 (5__) ___-____"
                  />

          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label for="ePosta">E-posta Adresi </label>
            <input
              class="form-control"
              type="email"
              id="ePosta"
              required
              v-model="e_mail"
            />
          </b-form-group>
        </div>
        <div class="col-12 mt-4">
          <b-form-group class="add-style">
            <label for="ısAdresi">İş Adresi </label>
            <input
              class="form-control"
              type="text"
              id="ısAdresi"
              required
              maxlength="227"
              v-model="bussines_address"
            />
          </b-form-group>
        </div>
        <button class="mx-auto saveButton mt-3" @click="updateCompany">
          Kaydet
        </button>
      </div>
    </Card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { COMPANY } from "@/core/services/store/company.module.js";
import { mapGetters } from "vuex";
import moment from "moment";
import ApiService from '@/core/services/api.service';
import MaskedInput from 'vue-masked-input';

moment.locale("tr");
export default {
  data() {
    return {
      bussines_address: "",
      class_company: "1",
      company_id: "",
      document_number: "",
      e_mail: "",
      issue_date: "",
      office_phone: "",
      phone: "",
      real_estate_trade: "",
      site_membership: "",
      tax_number: "",
      title: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Firma Kartı", route: "/manage/company" },
    ]);
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      this.$store.dispatch(COMPANY, this.myUser).then((response) => {
        this.bussines_address = response.bussines_address;
        this.class_company = response.class;
        this.company_id = response.company_id;
        this.document_number = response.document_number;
        this.e_mail = response.e_mail;
        this.issue_date = moment(response.issue_date, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );
        
        this.office_phone = response.office_phone;
        this.phone = response.phone;
        this.real_estate_trade = response.real_estate_trade;
        this.site_membership = moment(
          response.site_membership,
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        this.tax_number = response.tax_number;
        this.title = response.title;
      });
    },
    updateCompany() {
      let data = {
        user_token: this.myUser,
        bussines_address: this.bussines_address,
        class: this.class_company,
        company_id: this.company_id,
        document_number: this.document_number,
        e_mail: this.e_mail,
        issue_date: moment( this.issue_date).format("DD/MM/YYYY"),
        office_phone: this.office_phone,
        phone: this.phone,
        real_estate_trade: this.real_estate_trade,
        site_membership:  moment( this.site_membership).format("DD/MM/YYYY"),
        tax_number: this.tax_number,
        title: this.title,
      };
      const formDataSend = new FormData()
      for (const key in data) {
        formDataSend.append(key, data[key]);
      }
      
      ApiService
        .put("company/company", formDataSend)
        .then((response) => {
          if (response.data.status.code === 200) {
            this.$generateNotification(
              this,
              "success",
              "Bilgiler Güncellenmiştir."
            );
          } else {
            this.$generateNotification(
              this,
              "error",
              "Bilgiler Güncellenirken bir hata ile karşılaşıldı."
            );
          }
        })
        .catch((err) => {
          this.$generateNotification(
            this,
            "error",
            "Bilgiler Güncellenirken bir hata ile karşılaşıldı."
          );
        });
    },
  },

  computed: {
    ...mapGetters(["myUser"]),
  },
  components: {
    MaskedInput,
  },
};
</script>
